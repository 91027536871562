import React, { Component } from 'react';
import { Nav, Navbar} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import {menu_data} from './utils';
import fao_logo_png from './images/fao-logo.png';

class Menu extends Component {

    render(){
        return(
            <div className="menu-container">
                <Navbar bg="headerBlue" variant="dark" expand="lg"  bs-class="empres-header"   >
                    <Navbar.Brand>
                        <a href="http://fao.org">
                        <img src={fao_logo_png} className="headerLogo headerLogoSeparator" alt="FAO" />
                        </a>
                    </Navbar.Brand>    
                    <Nav className="justify-content-center" >
                        <Nav.Item>
                            GHGI-AFOLU Navigator
                        </Nav.Item>
                    </Nav>
                </Navbar>
                <Navbar bg="headerWhite" variant="light" expand="lg" bs-class="empres-header">
                    <Nav variant="tabs">
                    {
                    Object.keys(menu_data).map(key => {
                        return(
                            <Nav.Item key={key}>
                                <NavLink exact to={menu_data[key].path} className="nav-link" activeClassName="active">
                                    {menu_data[key].title}
                                </NavLink>
                            </Nav.Item>
                        )
                    })
                    }
                    </Nav>
                </Navbar>
            </div>
        )
    }
}
export default Menu;