export const menu_data = {
  default: {
    "path":"/overview",
    "title":"Overview", 
    "iframe_src": "https://data.apps.fao.org/?clean&https://data.apps.fao.org/catalog/terriajs/config/7db765f4-9d30-4e81-86af-9a0b563a72bf.json"
  },
  country_gap_assessment: {
    "path":"/country_gap_assessment",
    "title":"Country Gap assessment", 
    "iframe_src": "https://tableau.apps.fao.org/views/GapAssessment_Country_EC/GapAssessment_Country?:embed=yes"
  },
  region_gap_assessment: {
    "path":"/region_gap_assessment",
    "title":"Region Gap assessment", 
    "iframe_src": "https://tableau.apps.fao.org/views/GapAssessment_Region_EC/GapAssessmentRegion?:embed=yes"
  },
};
