import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Error from './Error';
import Menu from './Menu';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {menu_data} from './utils';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css'
ReactDOM.render(
  <Router basename="/ghgi">
    <Menu/>
      <Switch>
        <Redirect exact={true} from="/" to={menu_data["default"].path} />
        {
        Object.keys(menu_data).map(key => {
            return(
              <Route key={key} path={menu_data[key].path} exact={true} render={(props) => <App {...props} menu_item={key}/>} />
            )
        })
        }
        <Route path="*" exact={true} render={(props) => <Error {...props}/>} />
      </Switch>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
